import React from 'react'
import { CenteredResponsiveContainer, Layout } from '@/components/Layout'
import { SEO } from '@/components/SEO'
import tw from 'twin.macro'

const ukGenderPayGapReportFilename = 'UK-Gender-Pay-Gap-Disclosure-2023.pdf'
const Anchor = tw.a`text-jump-red`
export default function Disclosures() {
  return (
    <Layout>
      <SEO title="Disclosures" />
      <section>
        <CenteredResponsiveContainer>
          <h1 className="text-3xl mb-5">Disclosures</h1>
          <div className="mb-5">
            RTS 27 Reports are available on&nbsp;
            <Anchor href="https://bestexhub.com/data/view/jump-trading-europe-bv">BestExHub</Anchor>
          </div>
          <div className="mb-5">
            A copy of our Pillar III disclosure can be obtained by written request to&nbsp;
            <Anchor href="mailto:compliance@jumptrading.com">compliance@jumptrading.com</Anchor>
          </div>
          <div className="mb-5">
            <Anchor href={`/${ukGenderPayGapReportFilename}`}>UK Gender Pay Gap Disclosure 2023</Anchor>
          </div>
        </CenteredResponsiveContainer>
      </section>
    </Layout>
  )
}
